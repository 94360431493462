<template>
  <div class="login-wrapper">
    <div class="login-head">
      <p class="logo">企业管理数字化平台</p>
    </div>
    <div class="login-main">
      <div class="login_account">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" type="text" :autofocus="focusIndex == 1" auto-complete="off" placeholder="输入用户名" @keyup.enter.native="toPassword">
              <i slot="prefix" class="input-icon el-icon-user"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input ref="vPWD" v-model="loginForm.password" type="password" :autofocus="focusIndex == 2" auto-complete="off" placeholder="输入密码" show-password @keyup.enter.native="userLogin">
              <i slot="prefix" class="input-icon el-icon-lock"></i>
            </el-input>
          </el-form-item>
          <el-form-item style="width:100%;">
            <el-button :loading="loading" size="medium" type="primary" style="width:100%;" @click="userLogin">
              <span v-if="!loading">登 录</span>
              <span v-else>登 录 中...</span>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="login-foot">  <span>Copyright © 2023  All Rights Reserved.</span> 责任田企业管理平台</div>
    <!-- <div class="login_form"></div>
    <div class="login_top"></div>
    <div class="login_bottom"></div>
    <div class="login_main">
      <div class="login_account">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" type="text" :autofocus="focusIndex == 1" auto-complete="off" placeholder="输入用户名" @keyup.enter.native="toPassword">
              <i slot="prefix" class="input-icon el-icon-user"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input ref="vPWD" v-model="loginForm.password" type="password" :autofocus="focusIndex == 2" auto-complete="off" placeholder="输入密码" show-password @keyup.enter.native="userLogin">
              <i slot="prefix" class="input-icon el-icon-lock"></i>
            </el-input>
          </el-form-item>
          <el-form-item style="width:100%;">
            <el-button :loading="loading" size="medium" type="primary" style="width:100%;" @click="userLogin">
              <span v-if="!loading">登 录</span>
              <span v-else>登 录 中...</span>
            </el-button>
          </el-form-item>
        </el-form>
      </div> -->
  </div>

  <!-- <div class="login">
    <div class="el-login-footer">
      <span>Copyright © 2018-2019 ruoyi.vip All Rights Reserved.</span>
    </div>
  </div> -->
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      focusIndex: 1,
      loginForm: {
        username: '',
        password: '',
        uuid: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '用户名不能为空' }],
        password: [{ required: true, trigger: 'blur', message: '密码不能为空' }]
      },
      loading: false,
      redirect: undefined
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    Object.assign(this.loginForm, this.$lgh.getStore('loginForm'))
    if (this.loginForm.username) this.focusIndex = 2
  },
  methods: {
    toPassword() {
      if (this.loginForm.username) {
        this.$refs.vPWD.focus()
      }
    },
    userLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          this.loading = true
          var res = await this.$lgh.get('api/auth/user/login', this.loginForm, {
            loading: false,
            fail: e => {
              this.loading = false
            }
          })
          this.$lgh.setStore('loginForm', { username: this.loginForm.username })
          this.loading = false
          this.$store.dispatch('Login', res)
          this.$router.push({ path: this.redirect || '/' })
        }
      })
    }
  }
}
</script>

<style lang="less">
.login-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #282839;
}
.login-head {
  height: 140px;
  p.logo {
    height: 80px;
    line-height: 80px;
    margin-top: 30px;
    margin-left: 100px;
    background: url('../assets/logo-w.png') no-repeat left center;
    background-size: auto 100%;
    padding-left: 100px;
    font-size: 34px;
    font-weight: bolder;
    color: #aaa;
    user-select: none;
  }
}
.login-main {
  flex: 1;
  background: url('../assets/image/account/login_bg.jpg') no-repeat center center;
}
.login-foot {
  height: 140px;
  text-align: center;
  line-height: 100px;
  color: #777;
  user-select: none;
}
.login_account {
  background-color: #282839;
  position: absolute;
  top: 50%;
  right: 10%;
  margin-top: -200px;
  width: 500px;
  height: 400px;
  border-radius: 10px;
  box-shadow: 0 0 40px 2px rgba(0, 0, 0, 0.2);
  // background: url('../assets/image/account0/login_center.png') no-repeat center center;
  .el-form {
    width: 300px;
    margin: 100px auto;
    .el-form-item {
      margin: 0 0 40px 0;
      .el-input {
        font-size: 16px;
      }
      input {
        border-bottom: solid #aaa 1px;
        border-left: 0px;
        border-right: 0px;
        border-top: 0px;
        border-radius: 10;
      }
      .el-button {
        background-color: #f7c438;
        border-radius: 10px;
        color: #111;
        font-size: 16px;
      }
    }
  }
}
.login_foot {
  width: 100%;
  height: 140px; /*background: url(images/account/foot.png) no-repeat center center #fff;*/
}
</style>
